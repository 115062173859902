import React from 'react'
import Carousel from './Carousel'
import {ImageList, ImageListItem} from '@mui/material';
import ET_Album_1 from '../Media/ET_Album_1.jpg'
import ET_Album_2 from '../Media/ET_Album_2.jpg'
import ET_Album_3 from '../Media/ET_Album_3.jpg'
import ET_Album_4 from '../Media/ET_Album_4.jpg'
import ET_Album_5 from '../Media/ET_Album_5.jpg'
import ET_Album_6 from '../Media/ET_Album_6.jpg'
import ET_Album_7 from '../Media/ET_Album_7.jpg'
import ET_Album_8 from '../Media/ET_Album_8.jpg'
import ET_Album_9 from '../Media/ET_Album_9.jpg'
import ET_Album_10 from '../Media/ET_Album_10.jpg'
import ET_Album_11 from '../Media/ET_Album_11.jpg'
import ET_Album_12 from '../Media/ET_Album_12.jpg'
import ET_Album_13 from '../Media/ET_Album_13.jpg'
import ET_Album_14 from '../Media/ET_Album_14.jpg'
import ET_Album_15 from '../Media/ET_Album_15.jpg'
import ET_Album_16 from '../Media/ET_Album_16.jpg'
import ET_Album_17 from '../Media/ET_Album_17.jpg'
import ET_Album_18 from '../Media/ET_Album_18.jpg'


const Album = () => {

    function srcset(image, size, rows = 1, cols = 1) {
        return {
          src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
          srcSet: `${image}?w=${size * cols}&h=${
            size * rows
          }&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    const itemData1 = [
        {
          img: ET_Album_1,
          title: 'Breakfast',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_2,
          title: 'Burger',
        },
        {
          img: ET_Album_3,
          title: 'Camera',
        },
        {
          img: ET_Album_4,
          title: 'Coffee',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_5,
          title: 'Hats',
          cols: 2,
        },
        {
          img: ET_Album_6,
          title: 'Honey',
          author: '@arwinneil',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_7,
          title: 'Basketball',
          cols: 2,
        },
        {
          img: ET_Album_8,
          title: 'Fern',
        },
        {
          img: ET_Album_9,
          title: 'Mushrooms',
        },
      ];

      const itemData2 = [
        {
          img: ET_Album_10,
          title: 'Breakfast',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_11,
          title: 'Burger',
        },
        {
          img: ET_Album_12,
          title: 'Camera',
        },
        {
          img: ET_Album_13,
          title: 'Coffee',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_14,
          title: 'Hats',
          cols: 2,
        },
        {
          img: ET_Album_15,
          title: 'Honey',
          author: '@arwinneil',
          rows: 2,
          cols: 2,
        },
        {
          img: ET_Album_16,
          title: 'Basketball',
          cols: 2,
        },
        {
          img: ET_Album_17,
          title: 'Fern',
        },
        {
          img: ET_Album_18,
          title: 'Mushrooms',
        },
      ];
    return (
      <>
        {/* <ImageList
        variant="quilted"
        cols={10}
        rowHeight={121}
        >
            {itemData1.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                />
                </ImageListItem>
            ))}
        </ImageList> */}
        <Carousel imageList = {[...itemData1, ...itemData2]} />
        {/* <ImageList
        variant="quilted"
        cols={10}
        rowHeight={121}
        >
            {itemData2.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                />
                </ImageListItem>
            ))}
        </ImageList> */}
      </>
    )

}

export default Album
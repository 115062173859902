import * as React from 'react';
import {Box, Typography, Button, Modal, Grid, CssBaseline, TextField, Container} from '@mui/material';
import {Link} from 'react-router-dom';
import ThirstyScriptExtraBoldDemoWoff from '../fonts/ThirstyScriptExtraboldDemo.woff';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ETLogo from '../Media/ETLogo.JPG'


const Navbar = (props) => {
    const location = useLocation();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [navLocation, setNavLocation] = React.useState(location.pathname);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '25vw',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        "text-align":'center'
    };

    const thirstyScript = {
        fontFamily: 'ThirstyScript',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 1000,
        src:  `
          local('ThirstyScript'),
          local('ThirstyScriptExtraboldDemo'),
          url(${ThirstyScriptExtraBoldDemoWoff}) format('woff')
        `,
    }

    const titleTheme = createTheme({
        typography: {
            fontFamily: [
                'ThirstyScript'
            ].join(','),
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [thirstyScript]
                }
            }
        }
    })

    const login = () => {}

    return (
        <ThemeProvider theme={titleTheme}>
            <React.Fragment>
              <CssBaseline />
                        <Grid alignItems="center" justifyContent="center" container sx={{ flexGrow: 1, color: '#efedeeff', marginLeft: '2vw', marginTop: '5vh', textDecoration: 'none'}}>
                            <Grid item xs={6}  to='/' onClick={()=>setNavLocation('/home')} component={Link}>
                                <img width='100%' src={ETLogo}/>
                            </Grid>
                        </Grid>
                    <Grid alignItems="center" justifyContent="center" spacing={0} container>
                        <Grid item xs={3}/>
                        <Grid alignItems="center" justifyContent="center" item xs={2.5}>
                            <Button type='type' disabled={navLocation ==='/home'} to='/' component={Link} onClick={()=>setNavLocation('/home')}>Home</Button>
                            <Button type='type' disabled={navLocation ==='/meetOurDjs'} to='/meetOurDjs' component={Link} onClick={()=>setNavLocation('/meetOurDjs')}>Meet our DJ's</Button>
                            {props.quote?<Button type='type' disabled={navLocation ==='/requestQuote'} to='/requestQuote' component={Link} onClick={()=>setNavLocation('/requestQuote')}>Request Quote</Button>:null}
                            {props.canLogin?<Button type='type' disabled={navLocation ==='/register'} to='/register' component={Link} onClick={()=>setNavLocation('/register')}>Register</Button>: null}
                            {props.priceHidden?<></>:<Button type='type' disabled={navLocation ==='/pricing'} to='/pricing' component={Link} onClick={()=>setNavLocation('/pricing')}>Pricing</Button>}
                            <Button type='type' disabled={navLocation ==='/album'} to='/album' component={Link} onClick={()=>setNavLocation('/album')}>Album</Button>
                            {props.features?<Button type='type' disabled={navLocation ==='/features'} to='/features' component={Link} onClick={()=>setNavLocation('/features')}>Features</Button>: null}
                        </Grid>
                        <Grid item xs={3}/>
                        {props.canLogin?
                        <Grid item xs={1}>
                            <Button color="text" onClick={openModal}>Login</Button>
                        </Grid>
                        : <></>
                        }
                    </Grid>
                    <Modal
                        open={modalOpen}
                        onClose={closeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{"margin": 1}}>
                            Login
                            </Typography>
                            <Box sx={{"margin": 1}}>
                                <TextField style={{}} id="outlined-basic" label="Username" variant="outlined" />
                            </Box>
                            <Box sx={{"margin": 1}}>
                                <TextField type='password' id="outlined-basic" label="Password" variant="outlined" />
                            </Box>
                            <Box sx={{"margin": 1}}>
                                <Button variant="contained" onClick={login}>Login</Button>
                            </Box>
                            <Typography variant='body'>No account?</Typography><Link onClick={closeModal} to='/register'>Sign up</Link>
                        </Box>
                    </Modal>
            </React.Fragment>
        </ThemeProvider>
    )

}

export default Navbar
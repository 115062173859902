import React from 'react'
import {Grid, Typography, IconButton, Card, Container} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DJ_Edwards from '../Media/DJ_Edwards.jpg'

const Bio = (props) => {
    const backgroundStyle={
        backgroundImage:
          "url('stock_dj_image.jpg') ",
        backgroundColor: 'darkGrey',
      
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginTop: "1vh",
        paddingBottom:'2vh'
    }

    console.log(props.DJ)
    return (

        <Grid container>
            <Grid item xs={4}>
                <img
                    id={props.DJ.title}
                    src={props.DJ.img}
                    srcSet={props.DJ.img}
                    alt={props.DJ.title}
                    loading="lazy"
                />
            </Grid>
            <Grid item xs={8}>
                <Grid container>
                    <Grid item  xs={12}>
                        <Card style={{backgroundColor:'black', padding:15, 'text-align': 'center', marginTop: 10, marginBottom: 10, opacity:'85%'}}>
                            <Typography  color='white' variant='h3'>
                                {props.DJ.title} ({props.DJ.nickname})
                            </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={1.5}/>
                    <Grid item xs={10}>
                        <Card style={{backgroundColor:'white', opacity:'90%', padding: '3vw'}}>
                            <Typography variant='h6'>
                                About {props.DJ.firstName}
                            </Typography>
                            <br/>
                            <Container style={{ marginLeft: 10}}>
                                <Typography  variant='body'>
                                    {props.DJ.bio}
                                </Typography>
                            </Container>
                            <br/>
                            <Typography variant='h6'>
                               Contact info
                            </Typography>
                            <br/>
                            <Container style={{ marginLeft: 10}}>
                                <Typography  variant='body'>
                                    Phone: {props.DJ.phone}
                                    <br/>
                                    Email: {props.DJ.email}
                                </Typography>
                            </Container>
                            <br/>
                            <Typography style={{'textAlign': 'left', marginRight: 10}} variant='h6'>
                                Social Media
                                {props.DJ.fbId?
                                <IconButton color='primary' href={`https://www.facebook.com/${props.DJ.fbId}`}>
                                    <FacebookIcon/>
                                </IconButton>
                                :<></>}
                                {props.DJ.instaHandle?
                                <IconButton color='primary' href={`https://www.instagram.com/${props.DJ.instaHandle}`}>
                                    <InstagramIcon/>
                                </IconButton>
                                :<></>}
                                {props.DJ.ytId?
                                <IconButton color='primary' href={`https://www.youtube.com/c/${props.DJ.ytId}`}>
                                    <YouTubeIcon />
                                </IconButton>
                                :<></>}
                            </Typography>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Bio
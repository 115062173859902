import React from 'react'
import {Grid, Card, Typography, Divider} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const Price = () => {
    return (
                <Grid container spacing={3}>
                        <Grid item xs={10} style={{marginLeft:'1vw'}}>
                            <Typography color='white' variant='h3'>Wedding Packages</Typography>
                        </Grid>
                        <Grid item xs={10} style={{marginLeft:'1vw'}}>
                            <Typography color='white' variant='body'>Thank you for your interest in Entertain This! wedding packages. We offer three tiers, plus an
                            a-la-carte menu for the ultimate in flexibility. You deserve to have the wedding celebration you
                            always wanted with all the features that are important to you</Typography>
                        </Grid>
                        <Grid item xs={2.75}>
                        <Card style={{backgroundColor:'white', opacity:'60%', padding: 10, width: '100%', height: '100%','text-align': 'center', marginLeft:'1vw'}}>
                                <div style={{height:'25vh'}} >
                                <Typography color='black' variant='body'>Pricing for metro-Phoenix area </Typography>
                                <Typography color='primary' variant='caption'>(Apache Junction, Awatukee, Buckeye, Cave Creek, Chandler, Gilbert, Glendale, Goodyear, Litchfield Park, Mesa, Peoria, Phoenix, Queen Creek, Scottsdale, Surprise)</Typography>
                                </div>
                                <Divider color='white'/>
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <Typography  color='black' variant='body'>MC/DJ for your event</Typography>
                                </div>
                                <Divider color='white'/>
                                <div style={{height:'12vh'}} >
                                    <Typography color='black' variant='body'>{'Written timeline & worksheet with all the activities and your special music requests'}</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'16vh'}} >
                                <Typography color='black' variant='body'>Custom music playlists to match your musical preferences</Typography>
                                <br/>
                                <Typography color='primary' variant='caption'> (ceremony, cocktail hour, dinner, dancing, etc)</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'16vh'}} >
                                <Typography color='black' variant='body'>Professional Sound System</Typography>
                                <br/>
                                <Typography color='primary' variant='caption'>(Speakers, Amplifier, Serato DJ Pro, Microphones, Mixer, Numark NS7 controller)</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'11vh', paddingTop: '1.5vh'}} >
                                <Typography color='black' variant='body'>Dance floor lighting </Typography>
                                <br/>
                                <Typography color='primary' variant='caption'>(6 multi-color lights)</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'11vh', paddingTop: '1.5vh'}} >
                                <Typography color='black' variant='body'>Sweetheart/head table accent lighting</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'11vh', paddingTop: '1.5vh'}} >
                                <Typography color='black' variant='body'>{'Transportation & equipment setup at your venue'}</Typography>
                                </div>
                                <Divider color='white' />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <Typography color='black' variant='body'>Separate ceremony sound system</Typography>
                                </div>
                                <Divider color='white'/>
                                <div style={{height:'11vh', paddingTop: '1.5vh'}} >
                                <Typography color='black' variant='body'>{'Custom monogram graphic & projector'}</Typography>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card raised={true} style={{backgroundColor:'white', opacity:'90%', padding: 10, width: '100%', height: '100%','text-align': 'center'}}>
                                <div style={{height:'23vh', paddingTop: '4vh'}} >
                                <Typography variant='h3'>Sweetheart</Typography>
                                <Typography variant='h4'color='primary'>$995</Typography>
                                </div>
                                <Divider style={{marginTop: '5%'}}/>
                                <div style={{height:'11vh'}}>
                                    <CheckIcon fontSize='large' color='primary' />
                                    <br />
                                    <Typography variant='caption'>(up to 6 hours)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'12vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                <br />
                                <Typography variant='caption'>(2 lights, your choice of color)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' style={{'opacity':'0%'}}/>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' style={{'opacity':'0%'}}/>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card raised={true} style={{backgroundColor:'white', opacity:'90%', padding: 10, width: '100%', height: '100%','text-align': 'center'}}>
                                <div style={{height:'23vh', paddingTop: '4vh'}} >
                                <Typography variant='h3'>Princess</Typography>
                                <Typography variant='h4' color='primary'>$1,195</Typography>
                                </div>
                                <Divider  style={{marginTop: '5%'}} />
                                <div style={{height:'11vh'}}>
                                <CheckIcon fontSize='large' color='primary' />
                                <br />
                                <Typography variant='caption'>(up to 7 hours)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'12vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                <br />
                                <Typography variant='caption'>(4 lights, your choice of color)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary'/>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' style={{'opacity':'0%'}}/>
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={3}>
                            <Card raised={true} style={{backgroundColor:'white', opacity:'90%', padding: 10, width: '100%', height: '100%', 'text-align': 'center'}}>
                                <div style={{height:'23vh', paddingTop: '4vh'}} >
                                <Typography variant='h3'>Marquis</Typography>
                                <Typography variant='h4' color='primary'>$1,495</Typography>
                                </div>
                                <Divider  style={{marginTop: '5%'}} />
                                <div style={{height:'11vh'}}>
                                <CheckIcon fontSize='large' color='primary' />
                                <br />
                                <Typography variant='caption'>(up to 8 hours)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'12vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'16vh', paddingTop: '5vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                <br />
                                <Typography variant='caption'>(6 lights, your choice of color)</Typography>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary' />
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary'/>
                                </div>
                                <Divider />
                                <div style={{height:'11vh', paddingTop: '3vh'}} >
                                <CheckIcon fontSize='large' color='primary'/>
                                </div>
                                
                            </Card>
                        </Grid>
                        <Grid item xs={10} style={{marginLeft:'1vw'}}>
                        <Typography color='white' variant='h3'>A-La-Carte Services</Typography>
                    </Grid>
                    <Grid item xs={6} style={{marginLeft:'1vw'}}>
                        <Typography color='white' variant='body'>A-La-Carte Pricing. At Entertain This, you can have it your way! Pick your package and select any add-on menu item for the additional price shown</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <Card style={{backgroundColor:'white', opacity:'90%', padding: 10, width: '98%', height: '100%', marginLeft:'1vw', marginRight:'2vw'}}>
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography  style={{'textAlign': 'center'}} variant='h6' color='primary'>$100 </Typography>
                            </Grid>
                            
                            <Grid item xs={10}>
                                <Typography variant='body'>Custom designed monogram graphic and projector adds an elegant personal touch to your event. Depending on room layout, the monogram could be displayed on a wall, ceiling or floor.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$150/hour</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>For additional MC/DJ performance time beyond the included package MC/DJ performance time</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$200</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Medium up-lighting package (8 lights) creates a beautiful effect on walls and ceilings, adds color and ambiance to any room, your choice of color</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$400</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Large up-lighting package (16 lights) same as medium package, more lights for larger spaces</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$100</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Separate sound system (single 15” speaker and microphone) for additional location at the same venue (ceremony, cocktails on the patio, etc.)</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$50</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Custom audio editing for song mashup, dance performance, flash-mob etc. 5 minute maximum finished audio length</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$50</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>20” Mirrored ball and spotlights – because, why not!?</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$250</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>First dance on a cloud – C02 Fog Generator – it’s magical!</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$100</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Whisper quiet gasoline powered electrical generator for remote locations where electricity is not available</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{'textAlign': 'center'}} variant='h6' color='primary'>$650+</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant='body'>Photobooth, call for options</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                    </Grid>
                </Grid>
    )

}

export default Price
import React from 'react'
import {Grid,Typography, Card, Button} from '@mui/material';
import {Link} from 'react-router-dom'
import BrideAndKids from '../Media/BrideAndKids.jpg'
import LineDance from '../Media/LineDance.jpg'
import theKnot2021 from '../Media/theKnot2021.png'
import weddingWire2022 from '../Media/weddingWire2022.png'
import theKnot2022 from '../Media/theKnot2022.png'



const Home = (props) => {

    const cardStyle={backgroundColor:'rgba(0, 0, 0, 0.7)', padding: '2%'}
    return (
        <>
            <Card style={cardStyle}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} style={{'padding': '7%'}}>
                        <Typography variant='h4' color='white'>
                            Welcome Entertain This!
                        </Typography>
                        <br/>
                        <br/>
                        <Typography variant='h6' color='white'>
                            Entertain This! Mobile DJ Service works with you to deliver the perfect experience for you and your guests.
                            <br/>
                            <br/>
                            Let us help you select the types of entertainment, music, sound equipment, lighting, and customized activities for your wedding, corporate event, sporting activity, dance party, award ceremony, etc.
                            MC/DJ/Vocal services available.
                        </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img width='100%' src={BrideAndKids}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={LineDance} width='100%'/>
                    {/* <video width='100%' autoPlay muted loop>
                        <source src={danceStock} type='video/mp4'/>
                    </video> */}
                </Grid>
                <Grid item xs={12} md={6} style={{'paddingTop': '5%', 'paddingLeft': '5%', 'paddingRight': '5%'}}>
                    <Typography variant='h4' color='white'>
                        We cover the Phoenix Metro area and all of Arizona.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant='h6' color='white'>
                        Contact Entertain This! today at dana@entertainthis.biz for a free consultation & quote 💕  
                    </Typography>
                    <br/>
                    {props.quoteToggle?<Button to='/requestQuote' variant="contained" component={Link}>Request A Quote</Button>:<Typography variant='h6' color='white'></Typography>}
                </Grid>
                <Grid item xs={12} md={12} style={{'textAlign': 'center','paddingTop': '5%', 'paddingLeft': '5%', 'paddingRight': '5%'}}>
                    <Typography variant='h6' color='white'>
                        click to see reviews
                    </Typography>
                </Grid>
                <Grid item xs={12} s={6} md={4} style={{'text-align': 'center'}}>
                    <a href={'https://www.theknot.com/marketplace/entertain-this-mobile-dj-service-phoenix-az-2029129'}>
                        <img  height='150vh'src={theKnot2021} />
                    </a>
                </Grid>
                <Grid item xs={12} s={6} md={4} style={{'text-align': 'center'}}>
                    <a href={'https://www.theknot.com/marketplace/entertain-this-mobile-dj-service-phoenix-az-2029129'}>
                        <img height='150vh' src={theKnot2022} />
                    </a>
                </Grid>
                <Grid item xs={12} s={6} md={4} style={{'text-align': 'center'}}>
                <a href={'https://www.weddingwire.com/landings/empresa.php?idEmpresa=1872239'}>
                        <img height='150vh' src={weddingWire2022} />
                    </a>
                </Grid>
            </Grid>
            </Card>
        </>
    )

}

export default Home
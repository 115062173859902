import React from 'react'
import {Button, Grid, Card, TextField, Typography, Box} from '@mui/material';

const Register = () => {
    const style = {
        margin: '5px'
    }

    return (
        <Grid container>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={6}>
                <Box style={{padding: 20}}>
                    <Card style={{backgroundColor:'white', opacity:'90%', padding: 10}}>
                    <Typography variant='h4' style={style}> Register for an account</Typography>
                    <Typography style={style}>Sign up for an account to manage payment information and make payments through the client portal</Typography>
                    <TextField style={style} size='small' label="First Name" variant="outlined" />
                    <TextField style={style} size='small' label="Last Name" variant="outlined" />
                    <br/>
                    <TextField style={style} size='small' label="Email Address" variant="outlined" />
                    <br/>
                    <TextField style={style} size='small' label="Phone Number" variant="outlined" />
                    <br/>
                    <TextField style={style} size='small' label="Username" variant="outlined" />
                    <br/>
                    <TextField style={style} type='password' size='small' label="Password" variant="outlined" />
                    <br/>
                    <TextField style={style} type='password' size='small' label="Re-enter Password" variant="outlined" />
                    <br/>
                    <Button style={style} variant="contained">Register</Button>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={3} />
        </Grid>
    )

}

export default Register
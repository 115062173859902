import React from 'react';
import { Routes, BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import Register from './Components/Register';
import RequestQuote from './Components/Quote';
import AboutUs from './Components/AboutUs';
import Footer from './Components/Footer';
import Features from './Components/Features';
import Pricing from './Components/Pricing';
import Album from './Components/Album'
import { createTheme, ThemeProvider, responsiveFontSizes, } from '@mui/material/styles';
import {Container, getContainerUtilityClass, Grid} from '@mui/material';
import toggles from './toggles.json'

function App() {
  let theme = createTheme({
    palette: {
      primary: {
        light: '#4C21E8',
        main: '#b30000',
        dark: '#37243F'
      },
      secondary: {
        light: '#EFEDEE',
        main: '#FFCF70',
        dark: '#F50800'
      },
      dark:{
        main: '#414141'
      },
      text: {
        main:'#efedeeff'
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      action: {
        disabledBackground: 'set color of background here',
        disabled: '#c9c9c9'
      }
    },
  });

  theme = responsiveFontSizes(theme);
  const backgroundStyle={
    backgroundImage: "url('stock_dj_image.jpg') ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    'max-width': '100%',
    display: 'flex',
    height: 'auto',
    'overflow-x': 'hidden'
  }

  return (
    <>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Grid spacing={0} container sx={{width: '100%'}} style={backgroundStyle}>
          <Grid item xs={12}>
            <Navbar canLogin={toggles.login} features={toggles.features} priceHidden={toggles.priceHidden} quote={toggles.quote}/>
            <Container sx={{marginLeft: '2vw', marginTop: '5vh'}}>
              <Routes>
                <Route exact path='/' element={<Home/>} quoteToggle={toggles.quote}/>
                <Route path='/register' element={<Register/>}/>
                <Route path='/requestQuote' element={<RequestQuote/>}/>
                <Route path='/meetOurDjs' element={<AboutUs />}/>
                <Route path='/album' element={<Album/>}/>
                <Route path='/features' element={<Features/>}/>
                <Route path='/pricing' element={<Pricing/>}/>
              </Routes>
            </Container>
          </Grid>
        </Grid>
        <Footer/>
      </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;

import React from 'react'
import {Grid,Typography, ImageListItem} from '@mui/material';
import DJ_Edwards from '../Media/DJ_Edwards.jpg'
import {Link} from 'react-router-dom'



const DJs = (props) => {

    const itemData = [
        {
          img: DJ_Edwards,
          title: 'Dana Edwards',
          handle: 'The Founder',
          id: 'dEdwards',
          instaHandle: 'entertainthisaz'
        }
      ];

    return (
                <>
                    <Grid container>
                    <Grid item xs={10} style={{marginLeft:'2vw'}}>
                            <Typography color='white' variant='h3'>Meet our DJ's</Typography>
                        </Grid>
                        <Grid item xs={12} style={{marginLeft:'2vw', marginRight:'2vw'}}>
                            <Typography color='white' variant='body'>Check out the DJ's we have on staff here at Entertain This! 
                            We will match your event with a great DJ to bring your event to life. Check out your DJ's bio to get a sneek peak of what to expect!</Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent='center' style={{padding: '2vw'}} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 12 }}>
                                {itemData.map((djItem, index) => (
                                    <Grid style={{width:'100%', 'align-text': 'center'}}item xs={3} sm={3} md={3} key={index}>
                                        <ImageListItem to={`/meetOurDjs#${djItem.id}`} style={{height:'50vh', width: '40vh'}} key={djItem.img} component={Link}>
                                            <img
                                                src={djItem.img}
                                                alt={djItem.handle}
                                                loading="lazy"
                                            />
                                            </ImageListItem>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
    )

}

export default DJs
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { IconButton, Typography, Grid} from '@mui/material';
import { Box } from '@mui/system';
import { YouTube } from '@mui/icons-material';
import ETLogo from '../Media/ETLogo.JPG'

const Footer = () => {
    return (
        <>
        <Box sx={{backgroundColor: '#0c0d0f'}}>
        <Grid container sx={{paddingTop: '4vh', paddingBottom: '2vh'}}>
            <Grid item xs={12} sm={4} sx={{textAlign:'center'}}>
                <Grid container sx={{textAlign:'center'}}>
                    <Grid item xs={12}>
                        <Typography  color='white' variant='h6' sx={{textAlign:'center'}}>Follow us on social media</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <IconButton color='primary' size='small' href={'https://www.facebook.com/EntertainThisMobileDJ'}><FacebookIcon /></IconButton>
                        <IconButton color='primary' size='small' href={'https://www.instagram.com/entertainthisaz/'}><InstagramIcon /></IconButton>
                    </Grid>
                    {/* <Grid item xs={2}>
                        <IconButton color='primary' size='small' to='youtube.com'><YouTube /></IconButton>
                    </Grid> */}
                    <Grid item xs={4}/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4} >
                <img width='100%' src={ETLogo}/>
            </Grid>
            <Grid item xs={12} sm={4} sx={{textAlign:'center'}}>
                <Typography color='primary' variant='h6'>Contact Us</Typography>
                <Typography color='white' variant='subtitle1'>Phone: 602.803.4403</Typography>
                <Typography color='white'variant='subtitle1'>Email: dana@entertainthis.biz</Typography>
            </Grid>
        </Grid>
        </Box>
        </>
    )

}

export default Footer
import { Typography } from '@mui/material'
import { Mailer } from 'nodemailer-react'

const transport = {
    host: 'smtp.example.com',
    secure: true,
    auth: { user: 'username', pass: 'password' },
    tls: {
        // do not fail on invalid certs
        rejectUnauthorized: false,
      }
}

const defaults = {
    from: "wandeltk@gmail.com",
}

export const quoteRequestEmail = (
    {   
        eventName, 
        startDate,
        endDate, 
        numHours, 
        location, 
        city, 
        otherCity, 
        eventType, 
        otherEventType, 
        schoolName, 
        sportEventName, 
        ceremony, 
        cocktailHour, 
        dinner, 
        reception, 
        firstName, 
        lastName, 
        role, 
        otherRole, 
        emailAddress, 
        phoneNumber, 
        additionalInfo
    }
    ) => ({
    subject: `Quote Request - ${eventName}`,
    body: (
      <>
        <Typography variant='h3'>Quote Request - {eventName}</Typography>
        <br/>
        <Typography variant='body'>Event Name: {eventName}</Typography>
        <Typography variant='body'>Start Date: {startDate}</Typography>
        <Typography variant='body'>End Date: {endDate}</Typography>
        <Typography variant='body'>Num Hours: {numHours}</Typography>
        <Typography variant='body'>Location: {location}</Typography>
        <Typography variant='body'>City: {city}</Typography>
        {city==='other'?<Typography variant='body'>Other City: {otherCity}</Typography>:<></>}
        <Typography variant='body'>Event Type: {eventType}</Typography>
        {eventType==='other'?<Typography variant='body'>Other Event Type: {otherEventType}</Typography>:<></>}
        {eventType==='schoolFunction'?<Typography variant='body'>School Name: {schoolName}</Typography>:<></>}
        {eventType==='sportingEvent'?<Typography variant='body'>Sport Event Name: {sportEventName}</Typography>:<></>}
        {eventType==='wedding'?<Typography variant='body'>Ceremony: {ceremony}</Typography>:<></>}
        {eventType==='wedding'?<Typography variant='body'>Cocktail Hour: {cocktailHour}</Typography>:<></>}
        {eventType==='wedding'?<Typography variant='body'>Dinner: {dinner}</Typography>:<></>}
        {eventType==='wedding'?<Typography variant='body'>Reception: {reception}</Typography>:<></>}
        <Typography variant='body'>First Name: {firstName}</Typography>
        <Typography variant='body'>Last Name: {lastName}</Typography>
        <Typography variant='body'>Role: {role}</Typography>
        {role==='other'?<Typography variant='body'>Other Role: {otherRole}</Typography>:<></>}
        <Typography variant='body'>Email Address: {emailAddress}</Typography>
        <Typography variant='body'>Phone Number: {phoneNumber}</Typography>
        <Typography variant='body'>Additional Info: {additionalInfo}</Typography>

      </>
    )
})

export const mailer = Mailer(
{ transport, defaults },
{ requestQuote: quoteRequestEmail}
)
import { Card } from '@mui/material'
import React, { useEffect } from 'react'

const Carousel = (props) => {
    const [currentImage, setCurrentImage] = React.useState(0);

    useEffect(()=>{
        console.log(currentImage, props.imageList.length)
        const timer = setTimeout(() => {
            if(currentImage < props.imageList.length - 1){
                setCurrentImage(currentImage + 1)
            } else {
                setCurrentImage(0)
            }
        }, 5000)
    })
    return (
        <>
            <Card style={{height:'85vh', display: 'flex','justify-content': 'center', backgroundColor:'rgba(0, 0, 0, 0.7)', padding: '2vw'}}>
                <img height='100%' src={props.imageList[currentImage].img}/>
            </Card>
        </>
    )
}

export default Carousel
import React from 'react'
import {Alert, AlertTitle, Button, Select, MenuItem, Grid, Card, TextField, Typography, Box,FormControl,InputLabel, Checkbox, FormControlLabel, Stack} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import {mailer} from '../Utils/emailWorker'


const RequestQuote = () => {
    const [role, setRole] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [eventName, setEventName] = React.useState('');
    const [eventStartDate, setEventStartDate] = React.useState('');
    const [eventEndDate, setEventEndDate] = React.useState('');
    const [eventStartDateTBD, setEventStartDateTBD] = React.useState(false);
    const [eventEndDateTBD, setEventEndDateTBD] = React.useState(false);
    const [numHours, setNumHours] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [city, setCity] = React.useState('');
    const [otherCity, setOtherCity] = React.useState('');
    const [eventType, setEventType] = React.useState('');
    const [schoolName, setSchoolName] = React.useState('');
    const [sportEventName, setSportEventName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [otherRole, setOtherRole] = React.useState('');
    const [otherEvent, setOtherEvent] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [cocktail, setCocktail] = React.useState(false);
    const [ceremony, setCeremony] = React.useState(false);
    const [dinner, setDinner] = React.useState(false);
    const [reception, setReception] = React.useState(false);
    const [additionalInfo, setAdditionalInfo] = React.useState(false);
    const [errors, setErrors] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState([]);
    const [submittable, setSubmittable] = React.useState(false);


    const style = {
        margin: '0.75vw'
    }

    const sendEmail = () => {
        let emailObject = {
            eventName: eventName, 
            startDate: eventStartDateTBD? 'TBD': eventStartDate.toString(),
            endDate: eventEndDateTBD? 'TBD': eventEndDate.toString(), 
            numHours:numHours, 
            location:location, 
            city: city, 
            otherCity: otherCity, 
            eventType: eventType, 
            otherEventType: otherEvent, 
            schoolName: schoolName, 
            sportEventName: sportEventName, 
            ceremony: ceremony, 
            cocktailHour: cocktail, 
            dinner: dinner, 
            reception: reception, 
            firstName: firstName, 
            lastName: lastName, 
            role: role, 
            otherRole: otherRole, 
            email: email, 
            phone: phone, 
            additionalInfo: additionalInfo
        }
        try{
            mailer.send('requestQuote', emailObject, {
                to: 'wandeltk@gmail.com'
            })
        } catch (e){
            setErrors(true)
            setErrorMessage('Something strange happened, please refresh and try again.')
        }
    }
    const validateRequired = () => {
        let validationErrors = []
        if(firstName===''||lastName===''){
            setErrors(true)
            validationErrors.push('Both first and last name are required so we can contact you later.')
        } if(eventName===''){
            setErrors(true)
            validationErrors.push('Please name the event so we can keep track of it.')
        } if((eventStartDate==='' && !eventStartDateTBD) || (eventEndDate==='' && !eventEndDateTBD)){
            setErrors(true) 
            validationErrors.push('If you do not have a start or end date decided for the event please check TBD.')
        } if(city===''){
            setErrors(true)
            validationErrors.push('Please enter the event city so we can give you an accurate quote.')
        } if(email===''){
            setErrors(true)
            validationErrors.push('Please enter your email so we can contact you back with the quote.')
        } if(!errors){
            setSubmittable(true);
            setErrors(false)
        } else if (errors){
            setErrorMessage(validationErrors)
        }
    }

    const submit = () => {
        try{
            validateRequired()
            if(!errors){
                sendEmail()
                location.replace(`/thankYou`)
            } else {
                throw(new Error('Email could not send'))
            }
        } catch(e){
            console.log(e)
            location.replace(`/error`)
        }
    }

    const handleInput = (event) => {
        switch(event.target.name){
            case "role":
                setRole(event.target.value);
                setOtherRole('')
                break;
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "eventName":
                setEventName(event.target.value);
                break;
            case "eventStartDate":
                setEventStartDate(event.target.value);
                break;
            case "eventEndDate":
                setEventEndDate(event.target.value);
                break;
            case "numHours":
                setNumHours(event.target.value);
                break;
            case "location":
                setLocation(event.target.value);
                break;
            case "city":
                setCity(event.target.value);
                setOtherCity('')
                break;
            case "otherCity":
                setOtherCity(event.target.value);
                break;
            case "eventType":
                setEventType(event.target.value);
                setSchoolName('')
                setSportEventName('')
                setCeremony(false)
                setCocktail(false)
                setDinner(false)
                setReception(false)
                break;
            case "schoolFunction":
                setSchoolName(event.target.value);
                break;
            case "sportingEvent":
                setSportEventName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "otherRole":
                setOtherRole(event.target.value);
                break;
            case "otherEvent":
                setOtherEvent(event.target.value);
                break;
            case "phone":
                setPhone(event.target.value);
                break;
            case "ceremony":
                setCeremony(!ceremony);
                break;
            case "cocktail":
                setCocktail(!cocktail);
                break;
            case "dinner":
                setDinner(!dinner);
                break;
            case "reception":
                setReception(!reception);
                break;
            case "additionalInfo":
                setAdditionalInfo(event.target.value);
                break;
            default:
                break;
        }

    }
    return (
            <Grid container>
                <Grid item xs={12}>
                    <Box style={{padding: 20}}>
                        <Card style={{backgroundColor:'white', opacity:'90%', padding: 10}}>
                        <Typography variant='h4' style={style}> Request a Quote</Typography>
                        <Typography style={style}>Tell us a little bit about your event so we can see if Entertain This! is be the best fit for you.</Typography>
                        <Grid container spacing={0}>
                            <Grid item style={{marginLeft: '1vh'}} xs={5}>
                                <Typography variant='caption' style={style}>Event Information</Typography>
                                <br/>
                                <TextField required fullWidth onChange={handleInput} onBlur={validateRequired} name='eventName' style={style} size='small' label="Event Name" variant="outlined" />
                                <br/>
                                <Stack style={style} direction="row" spacing={1} alignItems="center">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            disabled={eventStartDateTBD}
                                            style={style}
                                            label="Start Date"
                                            value={eventStartDate}
                                            onChange={(newValue) => {
                                                setEventStartDate(newValue);
                                            }}
                                            onBlur={validateRequired}
                                            minDate={new Date()}
                                            renderInput={(params) => <TextField {...params} />}
                                            />
                                    </LocalizationProvider>
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={eventStartDateTBD} onChange={() => {setEventStartDateTBD(!eventStartDateTBD)}} name="eventStartDateTBD" />
                                        }
                                        label="TBD"
                                    />
                                </Stack>
                                <Stack style={style} direction="row" spacing={1} alignItems="center">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            disabled={eventEndDateTBD}
                                            style={style}
                                            label="End Date"
                                            value={eventEndDate}
                                            onChange={(newValue) => {
                                                setEventEndDate(newValue);
                                            }}
                                            onBlur={validateRequired}
                                            minDate={eventStartDate? eventStartDate: new Date()}
                                            renderInput={(params) => <TextField {...params} />}
                                            />
                                    </LocalizationProvider>
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={eventEndDateTBD} onChange={() => setEventEndDateTBD(!eventEndDateTBD)} name="eventEndDateTBD" />
                                        }
                                        label="TBD"
                                    />
                                </Stack>
                                <TextField  onChange={handleInput} name='numHours' style={style} size='small' label="Number of Hours" variant="outlined" />
                                <br/>
                                <TextField fullWidth onChange={handleInput} name='location' style={style} size='small' label="Location/Venue" variant="outlined" />
                                <br/>
                                <FormControl>
                                <InputLabel style={{marginLeft: '0.6vw', marginTop: '0.25vw'}} id='event-city-label'>City *</InputLabel>
                                <Select
                                    sx={{minWidth: 220 }}
                                    labelId='event-city-label'
                                    style={style}
                                    size='small'
                                    value={city}
                                    label="City"
                                    onChange={handleInput}
                                    name='city'
                                    onBlur={validateRequired}
                                >
                                    <MenuItem value={'Phoenix'}>Phoenix</MenuItem>
                                    <MenuItem value={'Apache Junction'}>Apache Junction</MenuItem>
                                    <MenuItem value={'Awatukee'}>Awatukee</MenuItem>
                                    <MenuItem value={'Buckeye'}>Buckeye</MenuItem>
                                    <MenuItem value={'Cave Creek'}>Cave Creek</MenuItem>
                                    <MenuItem value={'Chandler'}>Chandler</MenuItem>
                                    <MenuItem value={'Gilbert'}>Gilbert</MenuItem>
                                    <MenuItem value={'Glendale'}>Glendale</MenuItem>
                                    <MenuItem value={'Goodyear'}>Goodyear</MenuItem>
                                    <MenuItem value={'Litchfield Park'}>Litchfield Park</MenuItem>
                                    <MenuItem value={'Mesa'}>Mesa</MenuItem>
                                    <MenuItem value={'Peoria'}>Peoria</MenuItem>
                                    <MenuItem value={'Queen Creek'}>Queen Creek</MenuItem>
                                    <MenuItem value={'Scottsdale'}>Scottsdale</MenuItem>
                                    <MenuItem value={'Surprise'}>Surprise</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                                </FormControl >
                                <TextField 
                                    onChange={handleInput} 
                                    name={city}
                                    helperText={'City'}
                                    style={{margin: '5px', visibility:`${city==='other'?'visible':'hidden'}`}} 
                                    variant="standard" />
                                <br/>
                                <FormControl>
                                <InputLabel style={{marginLeft: '0.6vw', marginTop: '0.25vw'}} id='event-type-label'>Event Type</InputLabel>
                                <Select
                                    sx={{minWidth: 220 }}
                                    labelId='event-type-label'
                                    style={style}
                                    size='small'
                                    value={eventType}
                                    label="Event Type"
                                    onChange={handleInput}
                                    name='eventType'
                                >
                                    <MenuItem value={'wedding'}>Wedding</MenuItem>
                                    <MenuItem value={'party'}>Party</MenuItem>
                                    <MenuItem value={'schoolFunction'}>School Function</MenuItem>
                                    <MenuItem value={'corporateEvent'}>Corporate Event</MenuItem>
                                    <MenuItem value={'sportingEvent'}>Sporting Event</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                                </FormControl >
                                <TextField 
                                    onChange={handleInput} 
                                    name={eventType}
                                    helperText={eventType==='schoolFunction'? 'Name of school' : eventType==='sportingEvent'? 'Name of Event' : eventType==='other'? 'Event type': ''}
                                    style={{margin: '5px', visibility:`${eventType==='schoolFunction'||eventType==='sportingEvent'||eventType==='other'?'visible':'hidden'}`}} 
                                    variant="standard" />
                                <br/>
                                <FormControl style={{marginLeft: '2vw', display:`${eventType==='wedding'?'flex':'none'}`}} >
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={ceremony} onChange={handleInput} name="ceremony" />
                                        }
                                        label="Ceremony"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={cocktail} onChange={handleInput} name="cocktail" />
                                        }
                                        label="Cocktail Hour"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={dinner} onChange={handleInput} name="dinner" />
                                        }
                                        label="Dinner"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={reception} onChange={handleInput} name="reception" />
                                        }
                                        label="Reception"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid style={{marginLeft: '1vh'}} item xs={12} md={5}>
                                <Typography variant='caption' style={style}>Contact Information</Typography>
                                <br/>
                                <TextField required  onBlur={validateRequired} onChange={handleInput} name='firstName' style={style} size='small' label="First Name" variant="outlined" />
                                <TextField required  onBlur={validateRequired} onChange={handleInput} name='lastName' style={style} size='small' label="Last Name" variant="outlined" />
                                <br/>
                                <FormControl>
                                <InputLabel style={{marginLeft: '0.6vw', marginTop: '0.25vw'}} id='role-label'>Role</InputLabel>
                                <Select
                                    sx={{minWidth: 230 }}
                                    labelId='role-label'
                                    style={style}
                                    size='small'
                                    value={role}
                                    label="Role"
                                    onChange={handleInput}
                                    name='role'
                                    required 
                                >
                                    {eventType==='wedding'?<MenuItem value={'bride/groom'}>Bride/Groom</MenuItem>:null}
                                    <MenuItem value={'parent'}>Parent</MenuItem>
                                    <MenuItem value={'organizer'}>Event Organizer</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                                </FormControl >
                                <TextField helperText={'Role'} name='otherRole' onChange={handleInput} style={{visibility:`${role==='other'?'visible':'hidden'}`}} variant="standard" />
                                <br/>
                                <TextField required onBlur={validateRequired} onChange={handleInput} name='email' style={style} size='small' label="Email Address" variant="outlined" />
                                <br/>
                                <TextField onChange={handleInput} name='phone' style={style} size='small' label="Phone Number" variant="outlined" />
                                <br/>
                            </Grid>
                            <br/>
                            <Grid style={{marginLeft: '1vh', marginRight: '20vw'}} item xs={12}>
                                <TextField rows={4} multiline fullWidth onChange={handleInput} name='additionalInfo' style={style} size='small' label="Additional Information" variant="outlined" />
                            </Grid>
                        </Grid>
                        {errorMessage.length > 0?
                            <Alert severity="error">
                                <AlertTitle>Please Address the following errors before submitting</AlertTitle>
                                <ul>
                                {errorMessage.map((error, index) => (
                                    <li key={`error${index}`}>{error}</li>
                                ))}
                                </ul>
                            </Alert>
                            :
                            <></>
                        }
                        <div style={style}>
                            <Button disabled={!submittable} style={style} variant="contained" onClick={submit}>Submit</Button>
                        </div>
                        <br/>
                        <Typography style={style} variant='caption'>*Required field</Typography>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
    )

}

export default RequestQuote
import React from 'react'
import {useLocation} from 'react-router-dom';
import Bio from './Bio';
import DJs from './DJs';
import _ from 'lodash';
import DJ_Edwards from '../Media/DJ_Edwards.jpg'


const AboutUs = () => {

    let location = useLocation();

    const djData = [
        {
            img: DJ_Edwards,
            title: 'Dana Edwards',
            firstName: 'Dana',
            email: 'dana@entertainthis.biz',
            phone: '602.803.4403',
            nickname: 'The Founder',
            id: 'dEdwards',
            bio: 'The founder of Entertain This! and DJ of over a decade, Dana brings any crowd to their feet with his upbeat and fun personality. From birthdays to corporate events you can count on Dana to bring a reliable and proffesional experience to your event.',
            instaHandle: 'entertainthisaz',
            fbId: 'EntertainThisMobileDJ'
        }
    ];
    let thisDJId = location.hash.replace('#','');
    let thisDJ = _.find(djData, {id: thisDJId})
    return (
        <>
            {
                location.hash ? <Bio DJ={thisDJ}/> : <DJs djData={djData}/>
            }         
        </>
    )

}

export default AboutUs